const routes = [
  /*
    INFO PAGES
  */

  {
    path: '/',
    component: 'components/pages/home-page/HomePage',
    title: 'Home',
    exact: true
  },

  {
    path: '/search/:term/',
    component: 'components/pages/search-page/SearchPage',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-live-calculator',
    path: '/live-calculator/',
    component: 'components/pages/live-calculator-page/LiveCalculatorPage',
    title: 'Live Calculator',
    exact: true
  },
  {
    id: 'page-about',
    path: '/about/',
    component: 'components/pages/about/about-page/AboutPage',
    title: 'About Us',
    exact: true
  },
  {
    id: 'page-articles-of-organization',
    path: '/about/articles-of-organization/',
    component:
      'components/pages/about/articles-of-organization-page/ArticlesOfOrganizationPage',
    title: 'Articles of Organization',
    exact: true
  },
  {
    id: 'page-life-notes',
    path: '/about/life-notes/',
    component: 'components/pages/about/life-notes-page/LifeNotesPage',
    title: 'Life Notes',
    exact: true
  },
  {
    id: 'page-contact-us',
    path: '/about/contact-us/',
    component: 'components/pages/about/contact-us-page/ContactUsPage',
    title: 'Contact Us',
    exact: true
  },
  {
    id: 'page-privacy-policy',
    path: '/about/privacy-policy/',
    component: 'components/pages/about/privacy-policy-page/PrivacyPolicyPage',
    title: 'Privacy Policy',
    exact: true
  },
  {
    id: 'page-leadership-staff',
    path: '/about/leadership-staff/',
    component:
      'components/pages/about/leadership-staff-page/LeadershipStaffPage',
    title: 'Leadership & Staff',
    exact: true
  },
  {
    id: 'page-community',
    path: '/about/community/',
    component: 'components/pages/about/community-page/CommunityPage',
    title: 'Community',
    exact: true
  },
  {
    path: '/outreach/:slug/',
    component: 'components/pages/outreach-item-page/OutreachItemPage',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-faqs',
    path: '/faqs-forms/faqs/',
    component: 'components/pages/faqs-page/FaqsPage',
    title: 'FAQs & Forms',
    exact: true
  },
  {
    id: 'page-membership-benefits',
    path: '/membership-benefits/',
    component:
      'components/pages/membership-benefits/membership-benefits-page/MembershipBenefitsPage',
    title: 'Membership Benefits',
    exact: true
  },
  {
    id: 'page-get-educated',
    path: '/get-educated/',
    component:
      'components/pages/membership-benefits/get-educated-page/GetEducatedPage',
    title: 'Get Educated',
    exact: true
  },

  /**
   *  Wrong slug was setup in dxp and the link was indexed by google.
   *  Must be above generic route.
   */
  {
    from: '/membership-benefits/get-educated/veterans-life-insurance /',
    to: '/get-educated/veterans-life-insurance/',
    exact: true
  },

  // https://jaybird.atlassian.net/browse/MW-1874
  {
    from: '/get-educated/militaryhealthinsurance/',
    to: '/get-educated/military-health-insurance/',
    exact: true
  },

  // https://jaybird.atlassian.net/browse/MW-1931
  {
    from: '/get-educated/military-benefits-after-discharge/',
    to: '/get-educated/military-benefits-after-separation/',
    exact: true
  },

  {
    id: 'page-get-educated-single',
    path: '/get-educated/:slug/',
    component:
      'components/pages/membership-benefits/get-educated-item-page/GetEducatedItemPage',
    title: '',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-eligibility',
    path: '/membership-benefits/eligibility/',
    component:
      'components/pages/membership-benefits/eligibility-page/EligibilityPage',
    title: 'Eligibility',
    exact: true
  },
  {
    id: 'page-benefits-offered',
    path: '/membership-benefits/benefits-offered/',
    component:
      'components/pages/membership-benefits/benefits-offered-page/BenefitsOfferedPage',
    title: 'Benefits of Membership',
    exact: true
  },
  {
    id: 'page-scholarship-program',
    path: '/membership-benefits/benefits-offered/scholarship-program/',
    component:
      'components/pages/membership-benefits/scholarship-program-page/ScholarshipProgramPage',
    title: 'Scholarship Program',
    exact: true
  },
  {
    id: 'page-national-guard-certificates',
    path: '/ngcertificate/',
    component:
      'components/pages/national-guard-certificates-page/NationalGuardCertificatesPage',
    title: 'Certificates',
    sensitive: false,
    exact: true,
    sitemap: false
  },
  {
    id: 'page-app-certificates',
    path: '/certificates/:appId/:state?/',
    component: 'components/pages/certificates-page/CertificatesPage',
    title: 'Certificates',
    sensitive: false,
    exact: true,
    sitemap: false
  },
  {
    id: 'page-note',
    path: '/note/',
    component: 'components/pages/note-page/NotePage',
    title: 'Note',
    exact: true,
    sitemap: false
  },

  /*
    INSURANCE PLANS LANDING PAGES
  */

  {
    id: 'page-insurance-plans',
    path: '/insurance-plans/',
    component:
      'components/pages/insurance-plans/insurance-plans-page/InsurancePlansPage',
    title: 'Insurance Plans',
    exact: true
  },
  {
    id: 'page-tricare-lp',
    path: '/insurance-plans/tricare-supplement/',
    component:
      'components/pages/insurance-plans/tricare-supplement-page/TricareSupplementPage',
    title: 'TRICARE Supplement Insurance',
    exact: true
  },
  {
    id: 'page-decreasing-group-lp',
    path: '/insurance-plans/decreasing-group-term-life/',
    component:
      'components/pages/insurance-plans/decreasing-group-term-life-page/DecreasingGroupTermLifePage',
    title: 'Monthly Income Term Life Insurance',
    exact: true
  },
  {
    id: 'page-decreasing-group-spouse-lp',
    path:
      '/insurance-plans/decreasing-group-term-life/decreasing-group-term-sponsored-spouse/',
    component:
      'components/pages/insurance-plans/decreasing-group-term-sponsored-spouse-page/DecreasingGroupTermSponsoredSpousePage',
    title: 'Monthly Income Term Life Insurance for Sponsored Spouse',
    exact: true
  },
  {
    id: 'page-decreasing-group-app',
    path:
      '/insurance-plans/decreasing-group-term-life/application-decreasing-group-term/',
    component:
      'components/pages/insurance-plans/decreasing-group-term-life/application-decreasing-group-term-page/ApplicationDecreasingGroupTermPage',
    title: 'Decreasing Group Term for Military and Current Federal Employees',
    exact: true
  },
  {
    id: 'page-nexgen-child-lp',
    path: '/insurance-plans/nexgen-dependent/',
    component:
      'components/pages/insurance-plans/nexgen-dependent-page/NexgenDependentPage',
    title: 'Dependent Child Term Life Insurance',
    exact: true
  },
  {
    id: 'page-nexgen-adult-lp',
    path: '/insurance-plans/nexgen-adult-former-dependents/',
    component:
      'components/pages/insurance-plans/nexgen-adult-former-dependents-page/NexgenAdultFormerDependentsPage',
    title: 'Adult Former MBA Dependent Life Insurance',
    exact: true
  },
  {
    id: 'page-term-90-plus-lp',
    path: '/insurance-plans/term-90-plus/',
    component:
      'components/pages/insurance-plans/term-90-plus-page/Term90PlusPage',
    title: 'Military Term Insurance to age 90',
    exact: true
  },
  {
    id: 'page-federal-civilian-term-90-plus-lp',
    path: '/insurance-plans/federal-civilian-term-90-plus/',
    component:
      'components/pages/insurance-plans/federal-civilian-term-90-plus-page/FederalCivilianTerm90PlusPage',
    title: 'Federal Employee Term Life Insurance to age 90',
    exact: true
  },
  // {
  //   id: 'page-hospitalinsurance-lp',
  //   path: '/insurance-plans/hospitalinsurance/',
  //   component:
  //     'components/pages/insurance-plans/hospitalinsurance-page/HospitalinsurancePage',
  //   title: 'Hospital Indemnity Insurance',
  //   exact: true
  // },
  {
    id: 'page-term90-dynamic-lp',
    path: '/term90dynamicappentry/',
    component:
      'components/pages/term-90-dynamic-app-entry-page/Term90DynamicAppEntryPage',
    title: 'Term 90 Plus Dynamic Application Entry',
    exact: true
  },
  {
    id: 'page-level-term-lp',
    path: '/insurance-plans/level-term/',
    component: 'components/pages/insurance-plans/level-term-page/LevelTermPage',
    title: 'Level Term 10 or 20 Years Life Insurance',
    exact: true
  },
  {
    id: 'page-pet-insurance-lp',
    path: '/insurance-plans/petinsurance/',
    component:
      'components/pages/insurance-plans/petinsurance-page/PetInsurancePage',
    title: 'Pet Insurance',
    exact: true
  },
  {
    id: 'page-share-application',
    path: '/shareapp/:data?/',
    component: 'components/pages/share-app-page/ShareAppPage',
    title: '',
    exact: true,
    sitemap: false
  },

  /*
    PEOPLE LIKE YOU
  */

  {
    id: 'page-people-like-you',
    path: '/insurance-plans/people-like-you/',
    component:
      'components/pages/insurance-plans/people-like-you/people-like-you-page/PeopleLikeYouPage',
    title: 'People Like You',
    exact: true
  },
  {
    id: 'page-ply-veteran',
    path: '/insurance-plans/people-like-you/veteran/',
    component:
      'components/pages/insurance-plans/people-like-you/veteran-page/VeteranPage',
    title: 'Veterans',
    exact: true
  },
  {
    id: 'page-ply-active-guard-reserve',
    path: '/insurance-plans/people-like-you/active-guard-reserve/',
    component:
      'components/pages/insurance-plans/people-like-you/active-guard-reserve-page/ActiveGuardReservePage',
    title: 'Active, Guard & Reserves',
    exact: true
  },
  {
    id: 'page-ply-adult-former-dependent',
    path: '/insurance-plans/people-like-you/adult-former-dependent/',
    component:
      'components/pages/insurance-plans/people-like-you/adult-former-dependent-page/AdultFormerDependentPage',
    title: 'Adult Former Dependents',
    exact: true
  },
  {
    id: 'page-ply-separating-military-member',
    path: '/insurance-plans/people-like-you/separating-military-member/',
    component:
      'components/pages/insurance-plans/people-like-you/separating-military-member-page/SeparatingMilitaryMemberPage',
    title: 'Separating Military Member',
    exact: true
  },
  {
    id: 'page-ply-federal-employee',
    path: '/insurance-plans/people-like-you/federal-employee/',
    component:
      'components/pages/insurance-plans/people-like-you/federal-employee-page/FederalEmployeePage',
    title: 'Federal Employees',
    exact: true
  },
  {
    id: 'page-ply-retired-military',
    path: '/insurance-plans/people-like-you/retired-military/',
    component:
      'components/pages/insurance-plans/people-like-you/retired-military-page/RetiredMilitaryPage',
    title: 'Retired Military',
    exact: true
  },
  {
    id: 'page-ply-spouse',
    path: '/insurance-plans/people-like-you/spouse/',
    component:
      'components/pages/insurance-plans/people-like-you/spouse-page/SpousePage',
    title: 'Spouse',
    exact: true
  },

  /*
    PARTNERS LANDING PAGES
  */

  {
    id: 'page-earn-5000',
    path: '/earn-5000-points-mba-visa-signature-card/',
    component:
      'components/pages/earn-5000-points-mba-visa-signature-card-page/Earn5000PointsMbaVisaSignatureCardPage',
    title: 'Earn 5000 Points MBA Visa Signature Card',
    exact: true
  },
  {
    id: 'page-association-old-crows',
    path: '/association-old-crows/',
    component:
      'components/pages/association-old-crows-page/AssociationOldCrowsPage',
    title: 'Association of Old Crows',
    exact: true
  },
  {
    id: 'page-dla-foundatio',
    path: '/dla-foundation/',
    component: 'components/pages/dla-foundation-page/DLAFoundationPage',
    title: 'DLA Foundation',
    exact: true
  },
  {
    id: 'page-eangky',
    path: '/eangky/',
    component: 'components/pages/eangky-page/EangkyPage',
    title: 'EANGKY',
    exact: true
  },

  {
    id: 'page-2022-annual-report',
    path: '/annual-report/2022/',
    component: 'components/pages/2022-annual-report-page/AnnualReportPage',
    title: '2022 Annual Report',
    exact: true
  },

  {
    id: 'page-2023-annual-report',
    path: '/annual-report/2023/',
    component: 'components/pages/2023-annual-report-page/AnnualReportPage',
    title: '2023 Annual Report',
    exact: true
  },

  {
    id: 'page-2024-annual-report',
    path: '/annual-report/2024/',
    component: 'components/pages/2024-annual-report-page/AnnualReportPage',
    title: '2024 Annual Report',
    exact: true
  },

  {
    id: 'page-legalplans',
    path: '/legalplans/',
    component: 'components/pages/legalplans-page/LegalplansPage',
    title: 'Legal Plans',
    exact: true
  },

  {
    id: 'page-my-vet-benefits',
    path: '/My-Vet-Benefits/',
    component: 'components/pages/my-vet-benefits/MyVetBenefitsPage',
    title: 'MyVetBenefits',
    exact: true
  },

  {
    id: 'page-my-vet-benefits-insurance-plans',
    path: '/My-Vet-Benefits/insurance-plans/',
    component:
      'components/pages/my-vet-benefits-insurance-plans/insurance-plans-page/MyVetBenefitsInsurancePlansPage',
    title: 'MyVetBenefits Insurance Plans',
    exact: true
  },

  /*
    DIGITAL SIGNUP
  */

  {
    id: 'page-digital-signup',
    path: '/events/',
    component: 'components/pages/events-page/EventsPage',
    title: 'Events',
    exact: true
  },

  /*
    APPLY INSURANCE PAGE
  */

  {
    id: 'page-apply-insurance',
    path: '/insurance-plans/apply-insurance/',
    component:
      'components/pages/insurance-plans/apply-insurance-page/ApplyInsurancePage',
    title: 'Apply for Insurance',
    exact: true
  },
  {
    id: 'page-apply-insurance-term-90-plus',
    path: '/insurance-plans/apply-term-90-plus-insurance/',
    component:
      'components/pages/insurance-plans/apply-term-90-plus-insurance-page/ApplyTerm90PlusInsurancePage',
    title: 'Apply for Term 90 Plus Insurance',
    exact: true
  },
  {
    id: 'page-apply-insurance-federal-civilian-term-90-plus',
    path: '/insurance-plans/apply-federal-civilian-term-90-plus-insurance/',
    component:
      'components/pages/insurance-plans/apply-federal-civilian-term-90-plus-insurance-page/ApplyFederalCivilianTerm90PlusInsurancePage',
    title: 'Apply for Federal Civilian Term 90 Plus Insurance',
    exact: true
  },
  {
    id: 'page-apply-insurance-level-term',
    path: '/insurance-plans/apply-level-term-insurance/',
    component:
      'components/pages/insurance-plans/apply-level-term-insurance-page/ApplyLevelTermInsurancePage',
    title: 'Apply for Level Term Insurance',
    exact: true
  },
  // {
  //   id: 'page-apply-insurance-hospital',
  //   path: '/insurance-plans/apply-hospital-insurance/',
  //   component:
  //     'components/pages/insurance-plans/apply-hospital-insurance-page/ApplyHospitalInsurancePage',
  //   title: 'Apply for Hospital Insurance',
  //   exact: true
  // },
  {
    id: 'page-apply-insurance-tricare',
    path: '/insurance-plans/apply-tricare-insurance/',
    component:
      'components/pages/insurance-plans/apply-tricare-insurance-page/ApplyTricareInsurancePage',
    title: 'Apply for Tricare Insurance',
    exact: true
  },

  /*
    FINANCIAL RESOURCES
  */

  {
    id: 'page-financial-resources',
    path: '/membership-benefits/financial-resources/',
    component:
      'components/pages/membership-benefits/financial-resources/financial-resources-page/FinancialResourcesPage',
    title: 'Financial Resources',
    exact: true
  },
  {
    id: 'page-fr-military-resources',
    path: '/membership-benefits/financial-resources/military-resources/',
    component:
      'components/pages/membership-benefits/financial-resources/military-resources-page/MilitaryResourcesPage',
    title: 'Military Resources',
    exact: true
  },
  {
    id: 'page-fr-life-events',
    path: '/membership-benefits/financial-resources/life-events/',
    component:
      'components/pages/membership-benefits/financial-resources/life-events-page/LifeEventsPage',
    title: 'Life Events',
    exact: true
  },
  {
    path:
      '/membership-benefits/financial-resources/life-events/:sectionSlug/:eventId?/',
    component:
      'components/pages/membership-benefits/financial-resources/life-event-page/LifeEventPage',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-fr-calculators-auto-loan',
    path:
      '/membership-benefits/financial-resources/calculators/auto-loan-payment-calculator/',
    component:
      'components/pages/membership-benefits/financial-resources/calculators-page/CalculatorsPage',
    title: 'Auto Loan Payment Calculator',
    exact: true
  },
  {
    id: 'page-fr-calculators-needs',
    path:
      '/membership-benefits/financial-resources/calculators/life-insurance-needs-calculator/',
    component:
      'components/pages/membership-benefits/financial-resources/calculators-page/CalculatorsPage',
    title: 'Life Insurance Needs Calculator',
    exact: true
  },
  {
    id: 'page-fr-calculators-amortization',
    path:
      '/membership-benefits/financial-resources/calculators/loan-amortization-calculator/',
    component:
      'components/pages/membership-benefits/financial-resources/calculators-page/CalculatorsPage',
    title: 'Loan Amortization Calculator',
    exact: true
  },
  {
    id: 'page-fr-market-summaries',
    path: '/membership-benefits/financial-resources/market-summaries/',
    component:
      'components/pages/membership-benefits/financial-resources/market-summaries-page/MarketSummariesPage',
    title: 'Market Summaries',
    exact: true
  },
  {
    id: 'page-fr-articles',
    path: '/membership-benefits/financial-resources/articles/',
    component:
      'components/pages/membership-benefits/financial-resources/financial-articles-page/FinancialArticlesPage',
    title: 'Financial Articles',
    exact: true
  },
  {
    id: 'page-fr-articles-single',
    path: '/membership-benefits/financial-resources/articles/:slug/',
    component:
      'components/pages/membership-benefits/financial-resources/financial-articles-item-page/FinancialArticlesItemPage',
    title: '',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-fr-videos',
    path: '/membership-benefits/financial-resources/videos/',
    component:
      'components/pages/membership-benefits/financial-resources/videos-page/VideosPage',
    title: 'Videos',
    exact: true
  },
  {
    id: 'page-fr-video-item',
    path: '/membership-benefits/financial-resources/videos/:slug/',
    component:
      'components/pages/membership-benefits/financial-resources/videos-page/VideosPage',
    title: '',
    exact: false,
    sitemap: false
  },
  {
    id: 'page-fr-e-seminars',
    path: '/membership-benefits/financial-resources/e-seminars/',
    component:
      'components/pages/membership-benefits/financial-resources/e-seminars-page/ESeminarsPage',
    title: 'E-seminars',
    exact: true
  },
  {
    path: '/agent/:formId?/:slug?/',
    component: 'components/pages/agent/AgentPage',
    exact: true,
    sitemap: false
  },

  /*
    AUTH PAGES
  */

  {
    id: 'page-become-a-member',
    path: '/membership-benefits/become-a-member/',
    container: 'AuthPageContainer',
    component:
      'components/pages/membership-benefits/become-a-member-page/BecomeMemberPage',
    title: 'Become a Member',
    exact: true
  },
  {
    id: 'page-login',
    path: '/login/',
    container: 'AuthPageContainer',
    component: 'components/pages/login/login-page/LoginPage',
    title: 'Login',
    exact: true
  },
  {
    id: 'page-password-forgot',
    path: '/password-forgot/',
    container: 'AuthPageContainer',
    component: 'components/pages/password-forgot-page/PasswordForgotPage',
    title: 'Password Forgot',
    exact: true
  },
  {
    id: 'page-find-your-username',
    path: '/membership-benefits/find-your-username/',
    container: 'AuthPageContainer',
    component:
      'components/pages/membership-benefits/find-your-username-page/FindYourUsernamePage',
    title: 'Find Your Username',
    exact: true
  },
  {
    id: 'page-setup-username',
    path: '/membership-benefits/setup-username/',
    container: 'AuthPageContainer',
    component:
      'components/pages/membership-benefits/setup-username-page/SetupUsernamePage',
    title: 'Setup Username',
    exact: true
  },
  {
    id: 'page-login-superadmin',
    path: '/login/superadmin/',
    container: 'AuthPageContainer',
    component: 'components/pages/login/superadmin-page/SuperadminPage',
    title: 'Superadmin Login',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-password-reset',
    path: '/password-reset/',
    container: 'AuthPageContainer',
    component: 'components/pages/password-reset-page/PasswordResetPage',
    title: 'Password Reset',
    exact: true,

    sitemap: false
  },

  /*
    ONLINE APPLICATIONS
  */

  /* term-90-plus */

  {
    id: 'page-term-90-plus-app',
    path: '/insurance-plans/term-90-plus/term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/term-90-plus-app-page/Term90PlusAppPage',
    title: 'Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-term-90-plus-spouse-app',
    path:
      '/insurance-plans/term-90-plus/sponsored-spouse-term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/term-90-plus-spouse-app-page/Term90PlusSpouseAppPage',
    title: 'Sponsored Spouse Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-federal-civilian-term-90-plus-app',
    path:
      '/insurance-plans/federal-civilian-term-90-plus/term-90-plus-u-s-government-civilian-employees-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/federal-civilian-term-90-plus-app-page/FedCivTerm90PlusAppPage',
    title: 'Term 90 Plus For U.S. Government Employees Online Application',
    exact: true
  },
  {
    id: 'page-federal-civilian-term-90-plus-spouse-app',
    path:
      '/insurance-plans/federal-civilian-term-90-plus/term-90-plus-for-u-s-government-civilian-sponsored-spouse-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/federal-civilian-term-90-plus-spouse-app-page/FedCivTerm90PlusSpouseAppPage',
    title:
      'Term 90 Plus For U.S. Government Civilian Sponsored Spouse Online Application',
    exact: true
  },
  {
    id: 'page-term-90-plus-simplified-app',
    path:
      '/insurance-plans/term-90-plus/simplified-term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/term-90-plus-simplified-app-page/Term90PlusSimplifiedAppPage',
    title: 'Simplified Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-term-90-plus-spouse-short-app',
    path:
      '/insurance-plans/term-90-plus/short-sponsored-spouse-term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/term-90-plus-spouse-short-app-page/Term90PlusSpouseShortAppPage',
    title: 'Short Sponsored Spouse Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-term-90-plus-short-app',
    path:
      '/insurance-plans/term-90-plus/short-term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/term-90-plus-short-app-page/Term90PlusShortAppPage',
    title: 'Short Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-federal-civilian-term-90-plus-quick-step-app',
    path:
      '/insurance-plans/term-90-plus/federal-civilian-quick-step-term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/federal-civilian-term-90-plus-quick-step-app-page/FedCivTerm90PlusQuickStepAppPage',
    title:
      'Current Federal Employee Quick Step Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-term-90-plus-quick-step-app',
    path:
      '/insurance-plans/term-90-plus/quick-step-term-90-plus-online-application/',
    component:
      'components/pages/insurance-plans/applications/term-90-plus/term-90-plus-quick-step-app-page/Term90PlusQuickStepAppPage',
    title: 'Quick Step Term 90 Plus Online Application',
    exact: true
  },
  {
    id: 'page-level-term-app',
    path: '/insurance-plans/level-term/level-term-online-application/',
    component:
      'components/pages/insurance-plans/applications/level-term/level-term-app-page/LevelTermAppPage',
    title: 'Level Term Online Application',
    exact: true
  },
  {
    id: 'page-level-term-spouse-app',
    path: '/insurance-plans/level-term/level-term-spouse-online-application/',
    component:
      'components/pages/insurance-plans/applications/level-term/level-term-spouse-app-page/LevelTermSpouseAppPage',
    title: 'Level Term Spouse Online Application',
    exact: true
  },
  {
    id: 'page-federal-civilian-level-term-app',
    path:
      '/insurance-plans/level-term/federal-civilian-level-term-online-application/',
    component:
      'components/pages/insurance-plans/applications/level-term/federal-civilian-level-term-app-page/FedCivLevelTermAppPage',
    title: 'Current Federal Employee Level Term Online Application',
    exact: true
  },
  {
    id: 'page-federal-civilian-level-term-spouse-app',
    path:
      '/insurance-plans/level-term/federal-civilian-level-term-spouse-online-application/',
    component:
      'components/pages/insurance-plans/applications/level-term/federal-civilian-level-term-spouse-app-page/FedCivLevelTermSpouseAppPage',
    title: 'Current Federal Employee Level Term Spouse Online Application',
    exact: true
  },
  {
    id: 'page-nextgen-adult-app',
    path:
      '/insurance-plans/nexgen-adult-former-dependents/nexgen-adult-former-dependent-life-online-application/',
    component:
      'components/pages/insurance-plans/applications/nextgen/nextgen-adult-app-page/NextgenAdultAppPage',
    title: 'Adult Former MBA Dependent Life Online Application',
    exact: true
  },
  {
    id: 'page-nextgen-child-app',
    path:
      '/insurance-plans/nexgen-dependent/nexgen-child-dependent-online-application/',
    component:
      'components/pages/insurance-plans/applications/nextgen/nextgen-child-app-page/NextgenChildAppPage',
    title: 'Dependent Term Life Online Application',
    exact: true
  },

  // http://localhost:3000/insurance-plans/national-guard-insurance/national-guard-online-application/
  {
    id: 'page-national-guard-app',
    path:
      '/insurance-plans/national-guard-insurance/national-guard-online-application/',
    component:
      'components/pages/insurance-plans/applications/national-guard/national-guard-app-page/NationalGuardAppPage',
    title: 'National Guard Online Application',
    exact: true
  },

  // http://localhost:3000/insurance-plans/national-guard-insurance/quick-step-national-guard-online-application/
  {
    id: 'page-national-guard-member-quick-step-app',
    path:
      '/insurance-plans/national-guard-insurance/quick-step-national-guard-member-online-application/',
    component:
      'components/pages/insurance-plans/applications/national-guard/national-guard-member-quick-step-app-page/NationalGuardMemberQuickStepAppPage',
    title: 'Quick Step National Guard Application',
    exact: true
  },
  // http://localhost:3000/insurance-plans/national-guard-insurance/national-guard-maine-online-application/
  {
    id: 'page-national-guard-maine-app',
    path:
      '/insurance-plans/national-guard-insurance/national-guard-maine-online-application/',
    component:
      'components/pages/insurance-plans/applications/national-guard/national-guard-maine-app-page/NationalGuardMaineAppPage',
    title: 'National Guard Maine Online Application',
    exact: true
  },

  // http://localhost:3000/insurance-plans/national-guard-insurance/quick-step-national-guard-maine-online-application/
  {
    id: 'page-national-guard-maine-quick-step-app',
    path:
      '/insurance-plans/national-guard-insurance/quick-step-national-guard-maine-online-application/',
    component:
      'components/pages/insurance-plans/applications/national-guard/national-guard-maine-quick-step-app-page/NationalGuardMaineQuickStepAppPage',
    title: 'Quick Step National Guard Maine Online Application',
    exact: true
  },

  // http://localhost:3000/insurance-plans/national-guard-insurance/sponsored-spouse-national-guard-online-application/
  {
    id: 'page-national-guard-spouse-app',
    path:
      '/insurance-plans/national-guard-insurance/sponsored-spouse-national-guard-online-application/',
    component:
      'components/pages/insurance-plans/applications/national-guard/national-guard-spouse-app-page/NationalGuardSpouseAppPage',
    title: 'Sponsored Spouse National Guard Online Application',
    exact: true
  },

  // http://localhost:3000/insurance-plans/national-guard-insurance/quick-step-national-guard-online-application/
  {
    id: 'page-national-guard-quick-step-app',
    path:
      '/insurance-plans/national-guard-insurance/quick-step-national-guard-online-application/',
    component:
      'components/pages/insurance-plans/applications/national-guard/national-guard-quick-step-app-page/NationalGuardQuickStepAppPage',
    title: 'Quick Step National Guard Spouse Application',
    exact: true
  },
  {
    id: 'page-ad-and-d-app',
    path: '/ad-and-d-insurance/ad-and-d-insurance-online-application/',
    component:
      'components/pages/insurance-plans/applications/ad-and-d/ad-and-d-app-page/AdAndDAppPage',
    title: 'AD&D Insurance Online Application',
    exact: true
  },
  {
    id: 'page-ad-and-d-spouse-app',
    path:
      '/ad-and-d-insurance/ad-and-d-insurance-sponsored-spouse-online-application/',
    component:
      'components/pages/insurance-plans/applications/ad-and-d/ad-and-d-spouse-app-page/AdAndDSpouseAppPage',
    title: 'AD&D Insurance for Sponsored Spouse Online Application',
    exact: true
  },
  {
    id: 'page-tricare-app',
    path:
      '/insurance-plans/tricare-supplement/group-tricare-supplement-insurance-plan-online-application/',
    component:
      'components/pages/insurance-plans/applications/tricare/tricare-app-page/TricareAppPage',
    title: 'Group TRICARE Supplement Online Enrollment Form',
    exact: true
  },
  // {
  //   id: 'page-hospital-u65-app',
  //   path: '/insurance-plans/hospital/hospital-u65/',
  //   component:
  //     'components/pages/insurance-plans/applications/hospital/hospital-u65-app-page/HospitalU65AppPage',
  //   title: 'Hospital Form',
  //   exact: true
  // },
  // {
  //   id: 'page-hospital-o65-app',
  //   path: '/insurance-plans/hospital/hospital-o65/',
  //   component:
  //     'components/pages/insurance-plans/applications/hospital/hospital-o65-app-page/HospitalO65AppPage',
  //   title: 'Hospital Form',
  //   exact: true
  // },

  // http://localhost:3000/metlife-legal-plans-online-enrollment/
  {
    id: 'page-metlaw-legal-app',
    path: '/metlife-legal-plans-online-enrollment/',
    component:
      'components/pages/insurance-plans/applications/metlaw-legal/metlaw-legal-app-page/MetlawLegalAppPage',
    title: 'MetLife Legal Plans Online Enrollment',
    exact: true
  },

  /* life insurance dynamic app */

  {
    id: 'page-term90-dynamic-app',
    path: '/term90dynamicapp/',
    component: 'components/pages/term-90-dynamic-app-page/Term90DynamicAppPage',
    title: 'Term 90 Plus Dynamic Application',
    exact: true
  },

  /*
    MY ACCOUNT
  */

  {
    id: 'page-my-account',
    path: '/my-account/',
    container: 'MyAccountPageContainer',
    component: 'components/pages/my-account/my-account-page/MyAccountPage',
    title: 'My Account',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-ma-payment-center',
    path: '/my-account/payment-center/',
    container: 'MyAccountPageContainer',
    component:
      'components/pages/my-account/payment-center-page/PaymentCenterPage',
    title: 'Payment Center',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-ma-insurance-coverage',
    path: '/my-account/insurance-coverage/',
    container: 'MyAccountPageContainer',
    component:
      'components/pages/my-account/insurance-coverage-page/InsuranceCoveragePage',
    title: 'Insurance Coverage',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-ma-national-guard-certificates',
    path: '/my-account/certificates/',
    container: 'MyAccountPageContainer',
    component:
      'components/pages/my-account/national-guard-certificates-page/NationalGuardCertificatesPage',
    title: 'Certificates',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-ma-benefits',
    path: '/my-account/benefits/',
    container: 'MyAccountPageContainer',
    component: 'components/pages/my-account/benefits-page/BenefitsPage',
    title: 'Benefits',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-ma-financial-resources',
    path: '/my-account/financial-resources/',
    container: 'MyAccountPageContainer',
    component:
      'components/pages/my-account/financial-resources-page/FinancialResourcesPage',
    title: 'Financial Resources',
    exact: true,
    sitemap: false
  },
  {
    id: 'page-ma-ballot',
    path: '/my-account/ballot/',
    container: 'MyAccountPageContainer',
    component: 'components/pages/my-account/ballot-page/BallotPage',
    title: 'Ballot',
    exact: true,
    sitemap: false
  },

  /*
    CAMPAIGNS
  */

  {
    path: '/campaigns/4mil-lp-b/',
    component: 'components/pages/campaigns/four-mil-lp-b',
    title:
      'Military personnel like you have protected their families with life insurance from Military Benefit Association for 60 years.',
    exact: true
  },
  {
    id: 'page-cmp-10-20-level-term',
    path: '/campaigns/10-20-level-term/',
    component: 'components/pages/campaigns/ten-twenty-level-term',
    title:
      'Military personnel like you have protected their families with life insurance from Military Benefit Association for more than 60 years.',
    exact: true
  },
  {
    id: 'page-cmp-7-key-things',
    path: '/campaigns/7-key-things/',
    component: 'components/pages/campaigns/seven-key-things',
    title: '7 key things to know about life insurance',
    exact: true
  },
  // http://localhost:3000/campaigns/automation/
  {
    path: '/campaigns/automation/',
    component: 'components/pages/campaigns/automation',
    title:
      'Life insurance can help give your family an added level of security',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-air-force/',
    component: 'components/pages/campaigns/badge-honor-active-air-force',
    title:
      'We help keep Air Force families financially secure all across the globe.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-army/',
    component: 'components/pages/campaigns/badge-honor-active-army',
    title:
      'Whether you’re in the field or deployed, we help keep your family financially secure.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-marines/',
    component: 'components/pages/campaigns/badge-honor-active-marines',
    title:
      'Whether you’re at home or forward deployed, we help keep your family financially secure.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-military-callcenter/',
    component:
      'components/pages/campaigns/badge-honor-active-military-callcenter',
    title:
      'Military personnel like you have protected their families with life insurance from Military Benefit Association for 60 years.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-military/',
    component: 'components/pages/campaigns/badge-honor-active-military',
    title:
      'Military personnel like you have protected their families with life insurance from Military Benefit Association for 60 years.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-family/',
    component: 'components/pages/campaigns/badge-honor-active-family',
    title: 'Protecting your family starts here and now.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-navy/',
    component: 'components/pages/campaigns/badge-honor-active-navy',
    title:
      'Whether you’re ashore or underway, we help keep your family financially secure.',
    exact: true
  },
  {
    path: '/campaigns/badge-honor-active-sgli/',
    component: 'components/pages/campaigns/badge-honor-active-sgli',
    title:
      'Your life insurance from the military may not be enough for your family.',
    exact: true
  },
  {
    id: 'page-cmp-badge-honor-active-spouses',
    path: '/campaigns/badge-honor-active-spouses/',
    component: 'components/pages/campaigns/badge-honor-active-spouses',
    title:
      'Military spouses do so much for their families. That’s why they need life insurance.',
    exact: true
  },
  {
    path: '/campaigns/fedcivil-fullratechart/',
    component: 'components/pages/campaigns/fedcivil-fullratechart',
    title: 'Term life insurance coverage for current federal employees',
    exact: true
  },
  {
    path: '/campaigns/federallifeinsurance/',
    component: 'components/pages/campaigns/federallifeinsurance',
    title: 'Term life insurance coverage for current federal employees',
    exact: true
  },
  {
    path: '/campaigns/life-insurance/',
    component: 'components/pages/campaigns/life-insurance',
    title: '4 reasons your spouse needs life insurance',
    exact: true
  },
  {
    path: '/campaigns/life-myths/',
    component: 'components/pages/campaigns/life-myths',
    title: '5 myths about life insurance',
    exact: true
  },
  {
    id: 'page-cmp-top-excuses',
    path: '/campaigns/top-excuses/',
    component: 'components/pages/campaigns/top-excuses',
    title: 'Top 4 excuses for not getting life insurance',
    exact: true
  },
  {
    path: '/campaigns/top-reasons/',
    component: 'components/pages/campaigns/top-reasons',
    title: 'Top 3 reasons you should have life insurance',
    exact: true
  },

  // http://localhost:3000/campaigns/liuna/
  {
    path: '/campaigns/liuna/',
    component: 'components/pages/campaigns/liuna',
    title:
      'LiUNA Believes in Readiness For Current Federal Employees of the National Guard and their Families',
    exact: true
  },

  // http://localhost:3000/campaigns/new-mexico-national-guard/
  {
    path: '/campaigns/new-mexico-national-guard/',
    component: 'components/pages/campaigns/new-mexico-national-guard',
    title:
      'New Mexico National Guard Believes in Readiness For National Guardsmen and their Families',
    exact: true
  },

  // http://localhost:3000/campaigns/benchmark-mortgage/
  {
    path: '/campaigns/benchmark-mortgage/',
    component: 'components/pages/campaigns/benchmark-mortgage',
    title:
      'Term Life Insurance to help pay off your mortgage, or meet whatever your family’s needs might be if the worst happens.',
    exact: true
  },

  {
    path: '/campaigns/poll/',
    component: 'components/pages/campaigns/poll',
    title: 'How much life insurance does a person in the mi',
    exact: true
  },
  {
    id: 'page-cmp-quick-step-fullratechart',
    path: '/campaigns/quick-step-fullratechart/',
    component: 'components/pages/campaigns/quick-step-fullratechart',
    title: 'Term life insurance coverage for members of the military',
    exact: true
  },
  {
    path: '/campaigns/quick-step-fullratecard-july-2017/',
    component: 'components/pages/campaigns/quick-step-fullratecard-july-2017',
    title: 'Term life insurance coverage for members of the military',
    exact: true
  },
  {
    path: '/campaigns/quick-step-fullratecard-oct-2017/',
    component: 'components/pages/campaigns/quick-step-fullratecard-oct-2017',
    title: 'Term life insurance coverage for members of the military',
    exact: true
  },
  {
    path: '/campaigns/quick-step-fullratecard-sep-2017/',
    component: 'components/pages/campaigns/quick-step-fullratecard-sep-2017',
    title: 'Term life insurance coverage for members of the military',
    exact: true
  },
  {
    path: '/campaigns/quick-step-fullratechart-may-2017/',
    component: 'components/pages/campaigns/quick-step-fullratechart-may-2017',
    title:
      'Term life insurance coverage for members of the military with full rate chart may 2017',
    exact: true
  },
  {
    id: 'page-cmp-quick-step',
    path: '/campaigns/quick-step/',
    component: 'components/pages/campaigns/quick-step',
    title: 'Term life insurance coverage for members of the military',
    exact: true
  },
  {
    id: 'page-cmp-term90-fullratechart',
    path: '/campaigns/april2019/term90-fullratechart/',
    component: 'components/pages/campaigns/term90-fullratechart/april2019',
    title:
      'Term life insurance coverage for members of the military and current federal employees',
    exact: true
  },
  {
    id: 'page-cmp-term90-fullratechart-2019',
    path: '/campaigns/2019/term90-fullratechart/',
    // Copy
    component: 'components/pages/campaigns/term90-fullratechart/april2019',
    title:
      'Term life insurance coverage for members of the military and current federal employees',
    sitemap: false,
    exact: true
  },
  {
    id: 'page-cmp-term90-fullratechart-nov-2020',
    path: '/campaigns/nov2020/term90-fullratechart/',
    component: 'components/pages/campaigns/term90-fullratechart/nov2020',
    title:
      'Term life insurance coverage for members of the military and current federal employees',
    exact: true
  },
  {
    path: '/campaigns/tricare/',
    component: 'components/pages/campaigns/tricare',
    title:
      'TRICARE, while an excellent program for military members and their families, does not cover ALL your medical expenses.',
    exact: true
  },

  // http://localhost:3000/campaigns/raytheon/
  {
    path: '/campaigns/raytheon/',
    component: 'components/pages/campaigns/raytheon',
    title:
      'Raytheon Believes in Readiness For Military Veterans and their Families.',
    exact: true
  },
  // http://localhost:3000/campaigns/readiness-level-term/
  {
    path: '/campaigns/readiness-level-term/',
    component: 'components/pages/campaigns/readiness-level-term',
    title:
      'Protecting your family’s future starts with Level Term life insurance.',
    exact: true
  },
  {
    path: '/campaigns/readiness-term-90/',
    component: 'components/pages/campaigns/readiness-term-90',
    title:
      'Protecting your family’s future starts with Term 90 Plus life insurance.',
    exact: true
  },
  {
    id: 'page-cmp-automation-uninsured',
    path: '/campaigns/automation/uninsured/',
    component: 'components/pages/campaigns/uninsured',
    title:
      'Life insurance can help give your family an added level of security',
    exact: true
  },

  // http://localhost:3000/campaigns/metlife-legal-plans/
  {
    id: 'page-cmp-metlaw-legal-services',
    path: '/campaigns/metlife-legal-plans/',
    component: 'components/pages/campaigns/metlaw-legal-services',
    title: 'Call to enroll in an important MBA benefit: MetLife Legal Plans.',
    exact: true
  },

  // http://localhost:3000/campaigns/lsc-lp/
  {
    path: '/campaigns/lsc-lp/',
    component: 'components/pages/campaigns/lsc-lp',
    title:
      'Military Benefit Association can help protect your family with life insurance.',
    exact: true
  },
  {
    path: '/campaigns/seven-reasons-why/',
    component: 'components/pages/campaigns/seven-reasons-why',
    title:
      'Your country and your family depend on you. But what if something should happen to you?',
    exact: true
  },
  {
    path: '/campaigns/financial-planning/',
    component: 'components/pages/campaigns/financial-planning',
    title: 'Financial planning is like military planning. You need a strategy.',
    exact: true
  },

  // http://localhost:3000/campaigns/fed-civ-lp-1/
  {
    path: '/campaigns/fed-civ-lp-1/',
    component: 'components/pages/campaigns/fed-civ-lp-1',
    title:
      'Don’t wait for a qualifying life event or FEGLI open enrollment. Add life insurance coverage now at special group term rates.',
    exact: true
  },

  // http://localhost:3000/campaigns/fed-civ-lp-2/
  {
    path: '/campaigns/fed-civ-lp-2/',
    component: 'components/pages/campaigns/fed-civ-lp-2',
    title: 'See how affordable it can be to add life insurance protection.',
    exact: true
  },

  // http://localhost:3000/campaigns/metlife/
  {
    id: 'page-cmp-metlaw',
    path: '/campaigns/metlife/',
    component: 'components/pages/campaigns/metlaw',
    title: 'Legal assistance on call. Ready when you need it.',
    exact: true
  },
  {
    path: '/campaigns/membership-benefits/',
    component: 'components/pages/campaigns/membership-benefits',
    title: 'Do you know all the benefits of your MBA membership?',
    exact: true
  },
  {
    path: '/campaigns/why-wait/',
    component: 'components/pages/campaigns/why-wait',
    title: 'Your life insurance is waiting. Why are you?',
    exact: true
  },
  {
    path: '/campaigns/hard-sell-two/',
    component: 'components/pages/campaigns/hard-sell-two',
    title: 'Your life insurance is waiting. Why are you?',
    exact: true
  },

  {
    path: '/beneficiary/',
    component:
      'components/templates/auto-download-page-template/AutoDownloadPageTemplate',
    fileId: 'file-beneficiary-designation-form',
    sitemap: false,
    exact: true
  },

  /*
    REDIRECTS
  */

  {
    from: '/campaigns/april2019/',
    toId: 'page-cmp-term90-fullratechart',
    exact: true
  },
  {
    from: '/campaigns/10-20-Level-Term/fullratecard/:date?/',
    toId: 'page-cmp-10-20-level-term',
    exact: true
  },
  {
    from:
      '(/campaigns/quick-step/fullratechart/|/campaigns/quick-step/fullratecard/)',
    toId: 'page-cmp-quick-step-fullratechart',
    exact: true
  },
  {
    from: '(/faqs/|/faqs-forms/)',
    toId: 'page-faqs',
    exact: true
  },
  {
    from: '/ad-and-d-insurance/',
    toId: 'page-ad-and-d-app',
    exact: true
  },
  // {
  //   from: '/hospitaldefense/',
  //   toId: 'page-hospitalinsurance-lp',
  //   query: 'defense',
  //   exact: true
  // },
  // {
  //   from: '/hospitaldefenseplus/',
  //   toId: 'page-hospitalinsurance-lp',
  //   query: 'plus',
  //   exact: true
  // },
  {
    from: '/campaigns/badge-honor-active-spouse/',
    toId: 'page-cmp-badge-honor-active-spouses',
    exact: true
  },
  {
    from: '/campaigns/quick-step/ageunder29/',
    toId: 'page-cmp-quick-step',
    exact: true
  },
  {
    from: '/campaigns/quick-step/age3034/',
    toId: 'page-cmp-quick-step',
    query: 'age=30-34',
    exact: true
  },
  {
    from: '/campaigns/quick-step/age3539/',
    toId: 'page-cmp-quick-step',
    query: 'age=35-39',
    exact: true
  },
  {
    from: '/campaigns/quick-step/age4044/',
    toId: 'page-cmp-quick-step',
    query: 'age=40-44',
    exact: true
  },
  {
    from: '/campaigns/quick-step/age4549/',
    toId: 'page-cmp-quick-step',
    query: 'age=45-49',
    exact: true
  },
  {
    from: '/campaigns/quick-step/age5054/',
    toId: 'page-cmp-quick-step',
    query: 'age=50-54',
    exact: true
  },
  {
    from: '/campaigns/quick-step/age5559/',
    toId: 'page-cmp-quick-step',
    query: 'age=55-59',
    exact: true
  },
  // should be case insensitive
  {
    from: '/tricare/',
    toId: 'page-tricare-lp',
    sensitive: false,
    exact: false
  },

  // MW-1401: metlaw => metlife

  {
    from: '/metlaw-legal-services-online-enrollment/',
    toId: 'page-metlaw-legal-app',
    exact: true
  },
  {
    from: '/campaigns/metlaw-legal-services/',
    toId: 'page-cmp-metlaw-legal-services',
    exact: true
  },
  {
    from: '/campaigns/metlaw/',
    toId: 'page-cmp-metlaw',
    exact: true
  },

  // MW-1604: news => get educated

  {
    from: '/about/news/',
    toId: 'page-get-educated',
    exact: true
  },
  {
    from: '/news/:slug/',
    toId: 'page-get-educated-single',
    exact: true
  },

  /*

  // MW-1604: scholarship => membership benefits

  {
    from: '/membership-benefits/benefits-offered/scholarship-program/',
    toId: 'page-benefits-offered',
    exact: true
  },

  */

  // MW-1648: /HIP => /insurance-plans/hospitalinsurance/

  // {
  //   from: '/hip/',
  //   toId: 'page-hospitalinsurance-lp',
  //   sensitive: false,
  //   exact: true
  // },

  {
    from: '/membership-benefits/get-educated/',
    toId: 'page-get-educated',
    exact: true
  },
  {
    from: '/membership-benefits/get-educated/:slug/',
    toId: 'page-get-educated-single',
    exact: true
  },

  {
    from: '/membership-benefits/financial-resources/calculators/',
    toId: 'page-fr-calculators-auto-loan',
    exact: true
  },

  // https://jaybird.atlassian.net/browse/MW-1823
  {
    from: '/pet-insurance/',
    toId: 'page-pet-insurance-lp',
    exact: true
  },

  {
    from: '/membership-benefits/financial-resources/video-alerts/',
    toId: 'page-fr-videos',
    exact: true
  }
];

export default routes;
