import { BRAND } from 'config/core';
import { getFileLink } from 'helpers/resources';

const hospitalConfig = {
  common: {
    brand: BRAND.hartford,
    paymentMethods: ['eft', 'cc', 'check'],
    // states that do not have "high", "medium", "low" options
    noPlansStates: ['NY']
  },
  'hospital-u65': {
    shortName: 'Hospital Indemnity Insurance U65',
    // appLink: getPageLink('page-hospital-u65-app'),
    minAge: 18,
    maxAge: 64,
    notAvailableForStates: [
      'AR',
      'ME',
      'MT',
      'NJ',
      'NM',
      'PR',
      'TX',
      'UT',
      'WA'
    ],
    paperStatesFlow: [
      {
        states: ['AZ', 'FL', 'ID', 'OR', 'WV'],
        pdf: getFileLink('file-mba-cw-under-age-65-hip-pod-enrollment-form')
      }
    ]
  },
  'hospital-o65': {
    shortName: 'Hospital Indemnity Insurance O65',
    // appLink: getPageLink('page-hospital-o65-app'),
    minAge: 65,
    maxAge: 84,
    notAvailableForStates: [
      'AR',
      'CA',
      'CT',
      'ME',
      'MA',
      'MT',
      'NJ',
      'NM',
      'OR',
      'PR',
      'TX',
      'UT',
      'WA'
    ],
    paperStatesFlow: [
      {
        states: ['AZ', 'FL', 'ID', 'WV'],
        pdf: getFileLink('file-mba-cw-over-age-65-hip-pod-enrollment-form')
      }
    ]
  }
};

export default hospitalConfig;
